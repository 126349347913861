<template>
  <div>
    <b-card
      v-if="$ability.can('manage', 'Startup')"
      fill
      pills
      class="shadow p-2"
    >
      <div class="mb-2">
        <b-link
          :to="{ name: 'startup-portal-details' }"
          class="btn btn-outline-primary btn-cart mr-2"
        >
          <plus-icon size="16" />
          Add Startup
        </b-link>
        <b-modal
          id="incubator-modal"
          cancel-title="Cancel"
          title="Select Startup"
          no-close-on-backdrop
        >
          <v-select
            v-model="selectedOrgId"
            class="flex-fill"
            :close-on-select="true"
            :get-option-label="option => option.title"
            :options="startupOrgs"
            :reduce="option => option.users_associationtables[0].id"
            placeholder="Select Startup"
            style="cursor:pointer"
            @input="$router.replace(`/startup-portal/incubator/${selectedOrgId}/manage`)"
          />
        </b-modal>
      </div>
      <!-- startup program -->
      <div>
        <b-row>
          <b-col md="4">
            <b-card class="border">
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Startup Programs
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="GridIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong>{{ startupTotalPrograms || 0 }}</strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link :to="{ name: 'startup-portal-program' }">

                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card class="border">
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Events
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="ActivityIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong>{{ startupTotalEvents || 0 }}</strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link :to="{ name: 'startup-portal-events' }">
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card class="border">
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Competitions
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="ZapIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong>{{ startupTotalCompetitions || 0 }}</strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link :to="{ name: 'startup-portal-competition' }">
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div class="d-flex justify-content-between mb-2">
        <h2>My Programs</h2>
        <b-link
          :to="{
            name: 'startup-program',
          }"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="startupPrograms.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>
        <section
          v-else-if="startupPrograms.length >= 1"
          class="ecommerce-application"
        >
          <div class="grid-view">
            <opportunities-card
              v-for="(program) in startupPrograms"
              :key="program.id"
              :opportunities-type="'program'"
              :program="program"
              :tab="'dashboard'"
            />
          </div>
        </section>
      </b-overlay>
      <hr class="my-2">
      <!-- Starup portal : My Upcoming Events -->
      <div class="d-flex justify-content-between mb-2">
        <h2>My Upcoming Events</h2>
        <b-link
          :to="{
            name: 'startup-portal-events',
          }"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="startupEvents.length < 1"
          class="text-center"
        >
          No events found under your organizations!
        </b-card>
        <section
          v-else-if="startupEvents.length >= 1"
          class="ecommerce-application"
        >
          <div class="grid-view">
            <opportunities-card
              v-for="program in startupEvents"
              :key="program.id"
              :opportunities-type="'events'"
              :program="program"
              :tab="'dashboard'"
            />
          </div>
        </section>
      </b-overlay>
      <hr class="my-2">
      <!-- startup portal-my upcoming competitions -->
      <div class="d-flex justify-content-between mb-2">
        <h2>My Upcoming Competitions</h2>
        <b-link
          :to="{
            name: 'startup-portal-competition',
          }"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="startupCompetitions.length < 1"
          class="text-center"
        >
          No competitions found under your organizations!
        </b-card>
        <section
          v-else-if="startupCompetitions.length >= 1"
          class="ecommerce-application"
        >
          <div class="grid-view">
            <opportunities-card
              v-for="program in startupCompetitions"
              :key="program.id"
              :opportunities-type="'competitions'"
              :program="program"
              :tab="'dashboard'"
            />
          </div>
        </section>
      </b-overlay>
    </b-card>
    <!-- mentor portal -->
    <b-card
      v-else-if="$ability.can('manage', 'Mentor')"
      fill
      pills
      class="shadow p-2"
    >
      <div>
        <b-row>
          <b-col md="4">
            <b-card class="border-secondary ">
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Startup Programs
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="GridIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong>{{ mentorPrograms.length || 0 }}</strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Mentor Invitations : 23</b-card-text>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link :to="{ name: 'mentor-programs' }">

                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card class="border-secondary ">
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Events
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="ActivityIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong> {{ mentorEvents.length || 0 }} </strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Mentor Invitations : 23</b-card-text>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link :to="{ name: 'mentor-events' }">

                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card class="border-secondary ">
              <div class="d-flex justify-content-between ">
                <b-card-text>
                  <strong>
                    Competitions
                  </strong>
                </b-card-text>
                <feather-icon
                  size="20"
                  icon="ZapIcon"
                />
              </div>
              <div class="d-flex align-items-start">
                <b-card-title
                  class="mr-2"
                  style="font-size: 2rem"
                >
                  <strong> {{ mentorCompetitions.length || 0 }} </strong>
                </b-card-title>
              </div>
              <div>
                <b-card-text>Mentor Invitations : 23</b-card-text>
                <b-card-text>Open for Registration : 23</b-card-text>
                <div class="d-flex justify-content-between align-items-start">
                  <b-card-text>My Applications : 23</b-card-text>
                  <b-link :to="{ name: 'mentor-competitions' }">
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="20"
                    />
                  </b-link>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <!-- Mentor portal : My Programs -->
      <div class="d-flex justify-content-between mb-2">
        <h2>My Programs</h2>
        <b-link
          :to="{ name: 'mentor-programs' }"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="mentorPrograms.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>

        <section class="ecommerce-application">
          <div class="grid-view">
            <opportunities-card
              v-for="(program, i) in mentorPrograms"
              :key="i"
              :opportunities-type="'program'"
              :program="program"
              :tab="'dashboard'"
            />
          </div>
        </section>
      </b-overlay>
      <hr class="my-2">
      <!-- Mentor portal : My events -->
      <div class="d-flex justify-content-between mb-2">
        <h2>My Events</h2>
        <b-link
          :to="{ name: 'mentor-events' }"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="mentorEvents.length < 1"
          class="text-center"
        >
          No events found under your organizations!
        </b-card>
        <section class="ecommerce-application">
          <div class="grid-view">
            <opportunities-card
              v-for="(program, i) in mentorEvents"
              :key="i"
              :opportunities-type="'events'"
              :program="program"
              :tab="'dashboard'"
            />
          </div>
        </section>
      </b-overlay>
      <hr class="my-2">
      <!-- Mentor portal : My competitions -->
      <div class="d-flex justify-content-between mb-2">
        <h2>My Competitions</h2>
        <b-link
          :to="{ name: 'mentor-competitions' }"
          class="btn btn-outline-primary"
        >
          <chevrons-right-icon size="16" />
        </b-link>
      </div>
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="mentorCompetitions.length < 1"
          class="text-center"
        >
          No competitions found under your organizations!
        </b-card>
        <section class="ecommerce-application">
          <div class="grid-view">
            <opportunities-card
              v-for="(program, i) in mentorCompetitions"
              :key="i"
              :opportunities-type="'competitions'"
              :program="program"
              :tab="'dashboard'"
            />
          </div>
        </section>
      </b-overlay>
    </b-card>
    <!-- Incubator dashboard -->
    <incubator-dashboard
      v-else-if="$ability.can('manage', 'Incubator')"
    />
    <span v-else>Some error occured. Please try logging out and log in again. </span>
    <b-modal
      id="view-program-modal"
      title="Title"
      hide-footer
      size="lg"
    >
      <b-img
        src="https://picsum.photos/600/300/?image=25"
        style="width: 100%;"
      />
      <b-table
        stacked
        bordered
        :items="items"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      id="view-event-modal"
      title="Event Title"
      hide-footer
      size="lg"
    >
      <b-img
        src="https://picsum.photos/600/300/?image=25"
        style="width: 100%;"
      />
      <b-table
        stacked
        bordered
        :items="eventItems"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
    <b-modal
      id="view-upcoming-competitions-modal"
      title="Competition Title"
      hide-footer
      size="lg"
    >
      <b-img
        src="https://picsum.photos/600/300/?image=25"
        style="width: 100%;"
      />
      <b-table
        stacked
        bordered
        :items="compItems"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>
<script>
import { ChevronsRightIcon, PlusIcon } from 'vue-feather-icons'
import OpportunitiesCard from '@/views/mentor-portal/opportunities/components/OpportunitiesCard.vue'
import gql from 'graphql-tag'
import {
  BCard, BCardText, BCardTitle, BImg, BLink, BModal, BOverlay, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    IncubatorDashboard: () => import('@/views/dashboard/v1/pages/IncubatorDashboard.vue'),
    BCard,
    BCardText,
    OpportunitiesCard,
    BTable,
    BCardTitle,
    BImg,
    BOverlay,
    BLink,
    BModal,
    ChevronsRightIcon,
    PlusIcon,
    vSelect,
  },
  data() {
    return {
      data: [
        {
          icon: 'ClockIcon',
          color: 'light-warning',
          title: 'Pre-Incubatee',
          total: null,
        },
        {
          icon: 'ActivityIcon',
          color: 'light-primary',
          title: 'Incubatee',
          total: null,
        },
        {
          icon: 'CheckIcon',
          color: 'light-success',
          title: 'Graduate',
          total: null,
        },
        {
          icon: 'UsersIcon',
          color: 'light-secondary',
          title: 'Network',
          total: null,
        },
      ],
      startupPrograms: [],
      slide: 0,
      sliding: null,
      applynow: [],
      items: [
        {
          'Organized By': 'xyz', 'Title ': 'Startup India', 'Type ': 'Online', 'Sector ': 'xyz', 'Funding Assitance': 'xyz', 'Lifecycle Stage': 'xyz', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023', 'Duration ': 1000,
        },
      ],
      eventItems: [
        {
          'Organized By': 'xyz', 'Event Title': 'Startup India', 'Event Type': 'Online', 'Description ': 'this is a description this is a description this is a description this is a description this is a description this is a description this is a description', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023',
        },
      ],
      compItems: [
        {
          'Organized By': 'xyz', 'Competition Title': 'Startup India', 'Competetion Type': 'Online', 'Description ': 'this is a description this is a description this is a description this is a description this is a description this is a description this is a description', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023', 'Competetion Prize': 1000, 'Prize Question 1': 'question competition 1', 'Prize Question 2': 'question competition 2',
        },
      ],
      applications: [],
      mentorPrograms: [],
      mentorCompetitions: [],
      mentorEvents: [],
      invitations: [],
      incubators: [],
      startupincubators: [],
      startupOrgs: [],
      selectedIndex: null,
      mutationLoading: false,
      selectedOrgId: null,
      startupTotalPrograms: 0,
      isUnderUserOrgs: !this.getIncubatorFromSubdomain(),
    }
  },
  computed: {
    ...mapGetters({
      associatedOrgDetails: 'user/getAssociatedOrgDetails',
      userDetails: 'user/getUserDetails',
      selectedOrgDetails: 'user/getSelectedOrgDetails',

    }),
  },
  methods: {
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    joinIncubator(incubatorId) {
      this.mutationLoading = true
      const mutation = gql`
        mutation {
          insert_users_associationtable_one(
            object: {
              user_id: ${this.userDetails.id},
              incubator_id: ${incubatorId},
              role: "mentor",
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully joined incubator\'s network.',
              icon: 'CompassIcon',
              variant: 'success',
            },
          })

          this.mutationLoading = false
          this.$apollo.queries.incubators.refetch()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong. Please try again later.',
              message: error.message,
              icon: 'CompassIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateResponse(status) {
      this.mutationLoading = true
      const stmt = [`
            update_users_associationtable_by_pk(pk_columns: {id: ${this.startupPrograms[this.selectedIndex].users_associationtables[0].id}}, _set: {status: "${status ? 'Accept' : 'Reject'}"}) {
              id
              status
            }`]
      // Update mentor table if accepted
      if (status === true) {
        stmt.push(`
            insert_programs_partnerstable_one(object: {user_id: ${this.userDetails.id}, program_id: ${this.startupPrograms[this.selectedIndex].id}, designation: ${this.startupPrograms[this.selectedIndex].users_associationtables[0].designation} }) {
              id
            }`)
      }
      this.$apollo.mutate({
        mutation: gql`mutation {${stmt.join('')}}`,
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_associationtable_by_pk.id ? 'Your response has been recorded' : 'Failed to submit response',
              icon: update_users_associationtable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_associationtable_by_pk.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.startupPrograms.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateInvResponse(status) {
      this.mutationLoading = true
      const stmt = [`
            update_users_associationtable_by_pk(pk_columns: {id: ${this.invitations[this.selectedIndex].users_associationtables[0].id}}, _set: {status: "${status ? 'Accept' : 'Reject'}"}) {
              id
              status
            }`]
      // Update mentor table if accepted
      if (status === true) {
        stmt.push(`
            insert_programs_partnerstable_one(object: {user_id: ${this.userDetails.id}, program_id: ${this.invitations[this.selectedIndex].id}, designation: ${this.invitations[this.selectedIndex].users_associationtables[0].designation}, is_jury: ${this.invitations[this.selectedIndex].users_associationtables[0].is_jury} }) {
              id
            }`)
      }
      this.$apollo.mutate({
        mutation: gql`mutation {${stmt.join('')}}`,
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_associationtable_by_pk.id ? 'Your response has been recorded' : 'Failed to submit response',
              icon: update_users_associationtable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_associationtable_by_pk.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.startupPrograms.refetch()
          this.$apollo.queries.invitations.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    startupPrograms: {
      query() {
        return gql`
          query startupPrograms($userOrg: Int!, $subdomain: String!) {
          programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: $subdomain}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}, programs_startupparticipants: {programs_applicantstable: {organization_id_id: {_eq: $userOrg}}}}) {
            id
            title
            begin_date
            capacity
            industry
            fund_raising_assistance
            description
            status
            type
            users_organizationtable {
              logo
              title
              type
            }
          }
        }`
      },
      variables() {
        return {
          userOrg: this.selectedOrgDetails.orgId,
          subdomain: this.getIncubatorFromSubdomain() || '.*',
        }
      },
      update(data) {
        return data.programs_basicinfo
      },
    },
    startupTotalPrograms: {
      query() {
        return gql`
          query startupTotalPrograms ($userOrg: Int!, $subdomain: String!, $type: [String!]) {
          programs_basicinfo_aggregate(where: {users_organizationtable: {domain: {_regex: $subdomain}}, type: {_in: $type}, programs_startupparticipants: {programs_applicantstable: {organization_id_id: {_eq: $userOrg}}}}) {
            aggregate {
              count(columns: id , distinct:true)
            }
          }
        }`
      },
      variables() {
        return {
          userOrg: this.selectedOrgDetails.orgId,
          subdomain: this.getIncubatorFromSubdomain() || '.*',
          type: ['Pre-incubation Program', 'Incubation Program', 'Accelerator Program'],
        }
      },
      update(data) {
        return data.programs_basicinfo_aggregate.aggregate.count
      },
    },
    startupEvents: {
      query() {
        return gql`
          query startupEvents($userOrg: Int!, $subdomain: String!) {
          programs_basicinfo(order_by: {id: desc}, limit: 3, where: {is_event: {_eq: true},users_organizationtable: {domain: {_regex: $subdomain}}, programs_startupparticipants: {programs_applicantstable: {organization_id_id: {_eq: $userOrg}}}}) {
            id
            title
            begin_date
            capacity
            industry
            fund_raising_assistance
            description
            status
            type
            users_organizationtable {
              logo
              title
              type
            }
          }
        }`
      },
      variables() {
        return {
          userOrg: this.selectedOrgDetails.orgId,
          subdomain: this.getIncubatorFromSubdomain() || '.*',
        }
      },
      update(data) {
        return data.programs_basicinfo
      },
    },
    startupTotalEvents: {
      query() {
        return gql`
          query startupTotalEvents($userOrg: Int!, $subdomain: String!) {
          programs_basicinfo_aggregate(where: {is_event: {_eq: true},users_organizationtable: {domain: {_regex: $subdomain}}, programs_startupparticipants: {programs_applicantstable: {organization_id_id: {_eq: $userOrg } } } }) {
            aggregate {
              count(columns: id , distinct:true)
            }
          }
        }`
      },
      variables() {
        return {
          userOrg: this.selectedOrgDetails.orgId,
          subdomain: this.getIncubatorFromSubdomain() || '.*',
        }
      },
      update(data) {
        return data.programs_basicinfo_aggregate.aggregate.count
      },
    },
    startupCompetitions: {
      query() {
        return gql`
          query startupCompetition($userOrg: Int!, $subdomain: String!) {
          programs_basicinfo(order_by: {id: desc}, limit: 3, where: {is_competition: {_eq: true},users_organizationtable: {domain: {_regex: $subdomain}}, programs_startupparticipants: {programs_applicantstable: {organization_id_id: {_eq: $userOrg}}}}) {
            id
            title
            begin_date
            capacity
            industry
            fund_raising_assistance
            description
            status
            type
            users_organizationtable {
              logo
              title
              type
            }
          }
        }`
      },
      variables() {
        return {
          userOrg: this.selectedOrgDetails.orgId,
          subdomain: this.getIncubatorFromSubdomain() || '.*',
        }
      },
      update(data) {
        return data.programs_basicinfo
      },
    },
    startupTotalCompetitions: {
      query() {
        return gql`
          query startupTotalCompetitions($userOrg: Int!, $subdomain: String!) {
          programs_basicinfo_aggregate(where: {is_competition: {_eq: true},users_organizationtable: {domain: {_regex: $subdomain}}, programs_startupparticipants: {programs_applicantstable: {organization_id_id: {_eq: $userOrg}}}}) {
             aggregate {
              count(columns: id , distinct:true)
            }
          }
        }`
      },
      variables() {
        return {
          userOrg: this.selectedOrgDetails.orgId,
          subdomain: this.getIncubatorFromSubdomain() || '.*',
        }
      },
      update(data) {
        return data.programs_basicinfo_aggregate.aggregate.count
      },
    },
    applynow: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
          programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_applicationformstables: {status: {_eq: "enabled"}, round_number: {_eq: 1}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}}) {
            begin_date
            capacity
            description
            id
            image
            industry
            title
            type
            programs_applicationformstables(where: {round_number: {_eq: 1}, status: {_eq: "enabled"}}) {
              id
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
    applications: {
      query() {
        return gql`
        query applications($userOrg: Int!, $subdomain: String!) {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: $subdomain}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}, programs_applicantstables: {organization_id_id: {_eq: $userOrg }}}) {
            id
            begin_date
            capacity
            description
            image
            industry
            organization_id_id
            title
            total_shortlisting_rounds
            type
          }
        }`
      },
      variables() {
        return {
          userOrg: this.selectedOrgDetails.orgId,
          subdomain: this.getIncubatorFromSubdomain() || '.*',
        }
      },
      update: data => data.programs_basicinfo,
    },
    mentorPrograms: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        query getPrograms  {
            programs_basicinfo( order_by: {id: desc}, limit: 3, where: {is_program: {_eq: true},users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_partnerstables: { user_id: {_eq: ${this.userDetails.id} }}}) {
                id
                title
                begin_date
                capacity
                description
                industry
                type
                total_shortlisting_rounds
                status
                users_organizationtable {
                title
                url
                  }
                programs_partnerstables(where: {user_id: {_eq: ${this.userDetails.id}}}) {
                  id
                  is_jury
                }
              }
            }`
      },
      update: data => data.programs_basicinfo,
    },
    mentorEvents: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        query getEvents {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {is_event: {_eq: true},users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_partnerstables: { user_id: {_eq: ${this.userDetails.id} }}}) {
                id
                title
                begin_date
                capacity
                description
                industry
                type
                total_shortlisting_rounds
                status
                users_organizationtable {
                title
                url
                  }
                programs_partnerstables(where: {user_id: {_eq: ${this.userDetails.id}}}) {
                  id
                  is_jury
                }
              }
            }`
      },
      update: data => data.programs_basicinfo,
    },
    mentorCompetitions: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
         query getCompetitions {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {is_competition: {_eq: true},users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_partnerstables: { user_id: {_eq: ${this.userDetails.id} }}}) {
                id
                title
                begin_date
                capacity
                description
                industry
                type
                total_shortlisting_rounds
                status
                users_organizationtable {
                title
                url
                  }
                programs_partnerstables(where: {user_id: {_eq: ${this.userDetails.id}}}) {
                  id
                  is_jury
                }
                 programs_deliverablelisttables {
                deliverable
                }
              }
            }`
      },
      update: data => {
        const res = data.programs_basicinfo.map(program => {
          const modifiedProgram = { ...program }
          modifiedProgram.deliverable = program.programs_deliverablelisttables.map(deliverable => deliverable.deliverable)
            .join(', ')
          return modifiedProgram
        })
        return res
      },
    },
    invitations: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
            programs_basicinfo(order_by: {id: desc}, limit: 3, where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, users_associationtables: {user_id: {_eq: ${this.userDetails.id} }}}) {
              begin_date
              capacity
              id
              industry
              description
              type
              title
              status
              users_organizationtable {
                title
                url
              }
              users_associationtables(where: {user_id: {_eq: ${this.userDetails.id} }}) {
                id
                designation
                status
                role
                is_jury
              }
            }
          }`
      },
      update: data => {
        data.programs_basicinfo.forEach(program => {
          // eslint-disable-next-line no-unused-expressions,no-param-reassign
          program.users_associationtables[0].readonly = !(program.users_associationtables[0].status.toLowerCase() === 'invited')
        })
        return data.programs_basicinfo
      },
    },
    incubators: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query Incubators {
            users_organizationtable(where: {domain: {_regex: "${subdomain}"}, type: {_ilike: "incubator"}}) {
              id
              title
              official_email
              official_phone
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {user_id: {_eq: ${this.userDetails.id} }}) {
                id
                role
                status
              }
            }
          }`
      },
      update: data => {
        if (!data.users_organizationtable.usersAssociationtablesByAssociatedOrg) {
          // this.$bvModal.show('join-incubator-modal')
        }
        return data.users_organizationtable
      },
    },
    startupincubators: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query Incubators {
            users_organizationtable(where: {type: {_ilike: "incubator"}, domain: {_regex: "${subdomain}"}}) {
              id
              title
              official_email
              official_phone
              url
              city
              state
              industry
              startup_stage
              description
              usersAssociationtablesByAssociatedOrg(where: {organization_id: {_eq: ${this.selectedOrgId} }}) @include(if: ${!!this.selectedOrgId}) {
                id
                role
                status
              }
            }
          }`
      },
      update: data => data.users_organizationtable,
    },
    startupOrgs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          query startup_organizations {
          users_organizationtable(where: {users_associationtables: { role: {_eq: "startup"}, incubator_id: {_is_null: true}, user_id: {_eq: "${this.userDetails.id}"}}})
            {
              id
              title
              users_associationtables(where: {usersOrganizationtableByIncubator: {domain: {_regex: "${subdomain}"}}, role: { _in: ["startup","incubatee","preincubatee","graduate"] }}) {
                id
              }
            }
        }`
      },
      update: data => {
        const temp = []
        data.users_organizationtable.forEach(orgs => {
          if (orgs?.users_associationtables.length > 0) {
            temp.push(orgs)
          }
        })
        return temp
      },
    },
  },

}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
